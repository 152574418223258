<template>
  <div class="design-view">
    <div class="sctp-mar-b15 sctp-container">
      <div class="sctp-pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>设计原稿大厅</el-breadcrumb-item>
          <el-breadcrumb-item>设计详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="notExist === true">
        <div style="padding: 50px 0;" class="sctp-bg-white">
          <empty :message="'设计不存在或已下架'"></empty>
        </div>
      </template>
      <template v-else>
        <template v-if="designDetailConfig.detail">
          <div class="goods-header pad15 sctp-bg-white">
            <el-row type="flex" :gutter="10">
              <el-col class="flex flex-col" :span="7">
                <div class="sctp-bg-white  flex-item1">
                  <template
                    v-if="designDetailConfig.detail.showimgurls && designDetailConfig.detail.showimgurls.length > 0">
                    <show-picture
                      :images="designDetailConfig.detail.showimgs"></show-picture>
                  </template>
                  <div class="pad-t10 flex flex-sb">
                    <div>
                      <a v-if="designDetailConfig.detail.favorite"
                         class="sctp-color-main"
                         @click="doPrivileged(favoriteProductConfig.onClick)">
                        <i class="el-icon-star-on"></i>
                        已收藏
                      </a>
                      <a v-else
                         @click="doPrivileged(favoriteProductConfig.onClick)">
                        <i class="el-icon-star-off"></i>
                        收藏商品
                      </a>
                    </div>
                    <a @click="reportConfig.showReport">
                      <i class="el-icon-warning"></i>
                      举报
                    </a>
                  </div>
                  <div class="view-control-wrap">
                    <div class="sctp-tc">
                      <div class="sctp-mar-b5">浏览数</div>
                      <div class="number">{{
                          designDetailConfig.detail && designDetailConfig.detail.hits || 0
                        }}
                      </div>
                    </div>
                    <div class="sctp-tc">
                      <div class="sctp-mar-b5">沟通数</div>
                      <div class="number">{{
                          designDetailConfig.detail && designDetailConfig.detail.views || 0
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col class="flex flex-col" :span="12">
                <div class="sctp-bg-white flex-item1 pad15">
                  <div class="sctp-font-24 productName">{{
                      designDetailConfig.detail && designDetailConfig.detail.designname || ' '
                    }}
                  </div>
                  <div
                    class="sctp-mar-tb15 sctp-bg-f8 sctp-pad-tb20 sctp-pad-lr20 sctp-info sctp-font-14 sctp-flex">
                    <div class="sctp-flex-item-1">
                      <div class="sctp-red sctp-font-28 sctp-mar-r15">
                        {{
                          designDetailConfig.detail && designDetailConfig.detail.saleprice
                          && `¥${designDetailConfig.detail.saleprice}` || '免费'
                        }}
                      </div>
                      <template
                        v-if="designDetailConfig.detail && designDetailConfig.detail.realprice">
                        <div style="text-decoration: line-through;">
                          原价：¥{{ designDetailConfig.detail.realprice }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    设计编号：{{ designDetailConfig.detail && designDetailConfig.detail.designid }}
                  </div>
                  <div>
                    设计类型：{{
                      designDetailConfig.detail && (designDetailConfig.detail.type + '/'
                        + designDetailConfig.detail.subType)
                    }}
                  </div>
                  <div class="sctp-pad-tb10 sctp-info">
                    服务由"{{ shopInfo && shopInfo.shopname || ' ' }}"发货，并提供售后服务。
                  </div>
                  <div>
                    <el-input-number
                      :disabled="!hasStock"
                      :step-strictly="true" v-model="buyConfig.count" :min="1"
                      :max=" designDetailConfig.detail && designDetailConfig.detail.stock || Infinity "
                    ></el-input-number>
                    <template
                      v-if="!designDetailConfig.detail || !designDetailConfig.detail.isAddCart">
                      <el-button
                        :disabled="!hasStock"
                        @click="doPrivileged(buyConfig.addCart)" type="primary"
                      >
                        加入购物车
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button @click="goPage('/shopping-cart')" type="primary">已在购物车</el-button>
                    </template>
                    <el-button
                      :disabled="!hasStock"
                      @click="doPrivileged(buyConfig.buyNow)" type="success"
                    >
                      立即购买
                    </el-button>
                    <span class="sctp-red" v-if="!hasStock">库存不足</span>
                  </div>
                  <div class="sctp-mar-t10 sctp-pad-b15"
                       style="border-top: 1px #e5e5e5 dashed;"></div>
                  <div>
                    <el-tabs value="second" type="card">
                      <el-tab-pane label="发货模式" name="second">
                        <div style="line-height: 2;">商家支持的发货模式：</div>
                        <div style="line-height: 2;" v-for="item in deliveryMode">{{ item }}</div>
                      </el-tab-pane>
                      <el-tab-pane label="交易形式" name="third">
                        <div>卖家期望的交易模式：{{ transactionForm }}</div>
                        <div class="fz-12">(提醒：私下交易可能存在风险)</div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="sctp-pad15 sctp-bg-white" style="border: 1px solid #eee;">
                  <div class="sctp-flex sctp-pad-b15 hairline-bottom">
                    <el-avatar class="sctp-mar-r10"
                               :src="shopInfo && shopInfo.logo"></el-avatar>
                    <div class="sctp-flex-item-1 sctp-flex sctp-flex-sb sctp-flex-column">
                      <div>{{ shopInfo && shopInfo.shopname }}的小店</div>
                      <div class="sctp-flex sctp-font-12">
                        <div class="sctp-mar-r5"><span><img class="auth_ok_icon"
                                                            src="/images/icon/shop_ok.png"/></span><span>店铺认证</span>
                        </div>
                        <div><span><img class="auth_ok_icon" src="/images/icon/user_ok.png"/></span><span>实名认证</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sctp-info sctp-font-14 sctp-pad-tb10 shop-info">
                    <div class="sctp-flex">
                      <div class="title">店家：</div>
                      <div class="value">{{ shopInfo && shopInfo.shopname }}</div>
                    </div>
                    <div class="sctp-flex">
                      <div class="title">宝贝：</div>
                      <div class="value">{{ shopInfo && shopInfo.goodsCount }}件</div>
                    </div>
                    <div class="sctp-flex">
                      <div class="title">地址：</div>
                      <div class="value">{{ shopAddress }}</div>
                    </div>
                    <div class="sctp-flex">
                      <div class="title">创店：</div>
                      <div class="value">{{
                          shopInfo && dateToString(shopInfo.createtime, 'yyyy-MM-dd')
                        }}
                      </div>
                    </div>
                    <div class="sctp-flex">
                      <div class="title">联系：</div>
                      <div class="value"><a class="sctp-blue"
                                              @click="doPrivileged(viewPhoneDialogConfig.onViewClick)">查看联系方式</a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <router-link :to="'/service-provider/view/' + (shopInfo && shopInfo.shopid || '')">
                      <el-button icon="el-icon-s-shop" size="mini">进入店铺</el-button>
                    </router-link>
                    <template
                      v-if="!(shopInfo && user && shopInfo.userid === user.userId)"
                    >
                      <el-button v-if="shopInfo && shopInfo.favorite"
                                 @click="doPrivileged(favoriteShop)"
                                 size="mini" icon="el-icon-star-on" type="primary"
                                 :loading="!collectionBtnCanClickFlag"
                                 class="mg-l5"
                      >
                        已收藏
                      </el-button>
                      <el-button v-else
                                 :loading="!collectionBtnCanClickFlag"
                                 @click="doPrivileged(favoriteShop)"
                                 size="mini" icon="el-icon-star-off"
                                 class="mg-l5"
                      >收藏店铺
                      </el-button>
                    </template>
                  </div>
                  <div class="mg-t10" v-if="shopInfo && shopInfo.deposit">
                    <el-alert
                      class="deposit-alter"
                      center
                      type="success"
                      :closable="false">
                      <div slot="title">
                        商家已缴纳保证金<span class="sctp-color-main fz-16">{{
                          shopInfo.deposit.toMoney()
                        }}</span>元
                      </div>
                    </el-alert>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="goods-body sctp-mar-t15">
            <el-row :gutter="15">
              <el-col :span="18">
                <el-tabs value="first" class="body-tabs" type="border-card">
                  <el-tab-pane label="商品详情" name="first">
                    <div class="">
                      <div class="">
                        <el-divider><span class="sctp-font-24">商品详情</span></el-divider>
                      </div>
                      <div class="sctp-pad15">
                        <div class="rich-text"
                             v-html="designDetailConfig.detail && designDetailConfig.detail.introduce || ' '"></div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="商品评价" name="third">
                    <div class="">
                      <div class="evaluate">
                        <comment type="DESIGN" :id="Number(id)"></comment>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="交易流程" name="fourth">
                    <div class="">
                      <div class="rich-text" v-html="transactionProcessConfig.content"></div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
              <el-col :span="6" class="">
                <div class="sctp-flex sctp-flex-sb sctp-pad10 sctp-bg-white">
                  <div>相似推荐</div>
                  <div></div>
                </div>
                <div class="sctp-bg-white">
                  <template v-for="(recommendItem,index) in recommendDesignConfig.data">
                    <a target="_blank" :href="'/design/view/' + recommendItem.designid"
                       :key="recommendItem.designid">
                      <div class="">
                        <div class="sctp-flex sctp-wfull sctp-pad5">
                          <el-image
                            class="sctp-mar-r10"
                            :src="recommendItem.designcoverImgurl"
                            fit="cover"
                            style="width:100px;height:100px;"
                          ></el-image>
                          <div class="sctp-flex-item-1">
                            <div class="flex flex-sb flex-col full">
                              <div class="sctp-ellipsis--l3">{{ recommendItem.designname }}</div>
                              <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">¥{{
                                  recommendItem.saleprice
                                }}</span></div>
                            </div>
                          </div>
                        </div>
                        <template v-if="index !== recommendDesignConfig.data.length - 1">
                          <div class="divider"></div>
                        </template>
                      </div>
                    </a>
                  </template>
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
      </template>
    </div>
    <el-dialog
      center
      title="查看联系方式"
      width="600px"
      :visible.sync="viewPhoneDialogConfig.showPayDialog"
    >
      <pay :pay-amount="shopInfo && shopInfo.viewContactPrice || 0"
           :can-use-reward="true"
           @pay="viewPhoneDialogConfig.onPay"
      ></pay>
    </el-dialog>

    <el-dialog
      center
      title="联系方式"
      width="500px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">手机号码：</div>
          <div>{{ shopInfo && shopInfo.phone || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
          <div>{{ shopInfo && shopInfo.email || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">QQ号：</div>
          <div>{{ shopInfo && shopInfo.qq || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信号：</div>
          <div>{{ shopInfo && shopInfo.wechat || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
          <div>
            <template v-if="shopInfo && shopInfo.wechat_qrcode">
              <el-image
                :src="shopInfo.wechat_qrcode"
                style="width: 80px;height: 80px;"
                fit="contain"
              ></el-image>
            </template>
            <template v-else>
              未设置
            </template>
          </div>
        </div>
        <div class="fz-12 fc-info">
          注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="设计信息内容举报"
      :visible.sync="reportConfig.show"
    >
      <report
        @success="reportConfig.hiddenReport"
        :target="{
               title:designDetailConfig.detail && designDetailConfig.detail.designname || '',
               id: id
            }"
        :type="4"></report>
    </el-dialog>

    <el-dialog
      title="平台服务条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="查看联系方式需支付服务费"
      :visible.sync="openPayAfterFlag"
      width="26%"
      class="register-dialog"
      center>
      <div>
        <div style="text-align: center;color: red;font-size: 20px">{{shopInfo.viewContactPrice.toMoney()}}元</div>
        <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        <div class="flex flex-center">
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasPhone"></i>
            <i class="el-icon-error" v-else></i>手机号</div>
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasEmail"></i>
            <i class="el-icon-error" v-else></i>邮箱</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasQQ"></i>
            <i class="el-icon-error" v-else></i>QQ</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasWechat"></i>
            <i class="el-icon-error" v-else></i>微信</div>
        </div>
      </div>
      <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
        <el-checkbox v-model="read">
          我已阅读
          <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
          并同意
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button @click="openPayAfterFlag = false" >取消</el-button>
        <el-button type="primary" @click="openPay()">支付</el-button>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      center
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="payShowFlag"
      @close="closeInter"
    >
      <template v-if="payShowFlag">
        <keep-alive>
          <s-please v-bind:price="shopInfo.viewContactPrice" v-bind:typename="typename"></s-please>
        </keep-alive>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import {cart, collection, common, design, goods, shop} from "@/apis/index";
import area from "@/resources/js/area";

export default {
  name: "design_view",
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    empty: () => import('@CMP/common/EmptyData.vue'),
    report: () => import('@CMP/common/UserReport'),
    comment: () => import('@PAGE/shop-manage/production-manage/production-commnet/CommentContent'),
    ShowPicture: () => import('@CMP/product/ShowPicture'),
  },
  props: {
    id: {
      required: true,
      type: Number,
    }
  },
  data() {
    return {
      centerDialogVisible:false,
      productReleaseTerms:null,
      openPayAfterFlag:false,
      read:false,
      notExist: null,
      shopInfo: {
        viewContactPrice:0
      },
      payShowFlag:false,
      payWay:null,
      typename:null,
      collectionBtnCanClickFlag: true,
      designDetailConfig: {
        detail: null,
        loadDetail: () => {
          return design.viewDetail({
            id: this.id,
            userId: (this.user && this.user.userId) || null,
          }).then(res => {
            let {data, shopInfo} = res;
            common.viewHistory({
              id: this.id,
              userId: (this.user && this.user.userId) || null,
              type: 3,
              title: data.designname
            });
            if (data.showimgurls) {
              data.showimgurls = data.showimgurls.split(',');
            }
            this.designDetailConfig.detail = data;
            this.shopInfo = shopInfo;
          }).catch(err => {
            if (err.retCode === '9000') {
              this.notExist = true;
            }
            return Promise.reject(err);
          });
        }
      },
      buyConfig: {
        count: 1,
        addCart: () => {
          if (this.designDetailConfig.detail.status ==0){
            this.$message.error("商品已下架或已经删除")
            return
          }
          if (!this.designDetailConfig.detail) {
            return Promise.reject();
          }
          return goods.addCart({
            userId: this.user.userId,
            goodsId: this.designDetailConfig.detail.designid,
            amount: this.buyConfig.count,
            type: 4,
          }).then(res => {
            this.$set(this.designDetailConfig.detail, 'isAddCart', true);
            this.$Bus.$emit('cartCountChange');
          })
        },
        buyNow: () => {
          let {detail} = this.designDetailConfig;
          cart.buyNow({
            id: this.id,
            type: 'DESIGN',
            amount: this.buyConfig.count
          });
        }
      },
      favoriteProductConfig: {
        onClick: () => {
          if (!this.favoriteProductConfig.canClick) {
            return;
          }
          this.favoriteProductConfig.canClick = false;
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: this.id,
            targetType: 4
          }).then(res => {
            const {data} = res;
            this.designDetailConfig.detail.favorite = data;
            if (data) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            this.favoriteProductConfig.canClick = true;
          })
        },
        canClick: true,
      },
      reportConfig: {
        show: false,
        showReport: () => {
          this.reportConfig.show = true
        },
        hiddenReport: () => {
          this.reportConfig.show = false
        }
      },
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
          onViewClick: () => {
          if (!this.shopInfo) {
            return;
          }
          if (this.shopInfo.showContact === true) {
            this.viewPhoneDialogConfig.show = true;
          } else {
            common.contactClickHistory({
              userId: this.user.userId,
              type: 4,
              id: this.id,
            })
            this.viewPhoneDialogConfig.confirmPay();
          }
        },
        confirmPay: () => {
          if (!this.shopInfo) {
            return;
          }
            this.openPayAfterFlag =true

          // let {shopInfo} = this;
          // this.$confirm(`<div>
          //     <div class="sctp-red sctp-font-18">${shopInfo.viewContactPrice.toMoney()}元</div>
          //     <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
          //     <div class="flex flex-center">
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasPhone
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasEmail
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasQQ
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasWechat
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
          //     </div>
          //
          //     </div>`, {
          //   dangerouslyUseHTMLString: true,
          //   confirmButtonText: '支付',
          //   cancelButtonText: '取消',
          //   center: true,
          //   title: '查看联系方式需支付服务费',
          // }).then(() => {
          //   this.viewPhoneDialogConfig.showPayDialog = true;
          // })
        },
        onPay: ({payWay}) => {
          this.payWay = payWay
          if (payWay != 3){
            if (payWay == 1){
              this.typename = "支付宝支付"
            }else if(payWay == 2){
              this.typename="微信支付"
            }
            this.payShowFlag = true
          }else{
            this.paySuccessOperate()
          }


        },
      },
      recommendDesignConfig: {
        data: [],
        getRecommend: () => {
          design.similarRecommendation({
            id: this.id
          }).then(res => {
            const {data} = res;
            this.recommendDesignConfig.data = data;
          })
        },
      },
      transactionProcessConfig: {
        content: '',
        loadData: () => {
          common.loadProtocol({
            protocolName: '服务交易流程'
          }).then(res => {
            let {data} = res;
            this.transactionProcessConfig.content = data;
          })
        }
      },
    }
  },
  created() {
    this.$Bus.$on("payStatus",()=>{
      this.paySuccessOperate()
    })
  },
  methods: {
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag =false
      this.viewPhoneDialogConfig.showPayDialog =true

    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {data} = res;
        this.productReleaseTerms = data
      })
    },
    /**
     * 收藏店铺
     */
    favoriteShop() {
      if (!this.shopInfo) {
        return;
      }
      this.collectionBtnCanClickFlag = false;
      collection.addFavorite({
        userId: (this.user && this.user.userId) || null,
        targetId: this.shopInfo.shopid,
        targetType: 1,
      }).then(res => {
        const {data} = res;
        this.shopInfo.favorite = data;
        if (data) {
          this.$message.success('收藏成功')
        } else {
          this.$message.success('取消收藏成功')
        }
      }).finally(() => {
        this.collectionBtnCanClickFlag = true
      })
    },
    closeInter(){
    },
    paySuccessOperate(){
      shop.viewContactInformation({
        userId: this.user.userId,
        shopId: this.shopInfo.shopid,
        payWay: this.payWay,
        eType: 4,
        eId: this.id
      }).then(() => {
        this.$message.success('支付成功')
        this.viewPhoneDialogConfig.showPayDialog = false;
        this.payShowFlag = false
        this.designDetailConfig.loadDetail().then(res => {
          this.viewPhoneDialogConfig.show = true;
        });
      })
    }
  },
  computed: {
    hasStock() {
      const {detail} = this.designDetailConfig;
      let flag = true;
      if (detail) {
        if (detail.stock !== undefined && detail.stock !== null) {
          flag = detail.stock > 0
        }
      }
      return flag
    },
    deliveryMode() {
      let {detail} = this.designDetailConfig;
      const form = {
        1: '邮件发送(手动)',
        2:  `网盘下载(自动) ${detail && detail.netdiscname || ''}`,
        3: '网站直接下载(自动)',
        4: '物流邮寄(手动)',
      };
      return (detail && detail.saletype && detail.saletype.split(',').map(item => form[item]))
        || [];
    },
    transactionForm() {
      let {detail} = this.designDetailConfig;
      const mode = {
        1: '私下交易(非担保交易)',
        2: '担保交易',
        3: '私下交易(非担保交易)/担保交易',
      };
      return (detail && mode[detail.dealmodel]) || ''
    },
    shopAddress() {
      let ret = '';
      if (this.shopInfo) {
        let province = area.parseProvince(this.shopInfo.provinceCode);
        let city = area.parseCity(this.shopInfo.cityCode);
        ret = `${province.name}-${city.name}`;
      }
      return ret;
    },
  },
  beforeMount() {
    this.designDetailConfig.loadDetail();
    this.recommendDesignConfig.getRecommend();
    this.transactionProcessConfig.loadData();
    this.getProductReleaseTerms();

    let scriptLoad = document.createElement('script')
    scriptLoad.type = 'text/javascript'
    scriptLoad.src = '/resources/js/qrious.js'
    document.body.appendChild(scriptLoad);
  }
}
</script>

<style scoped lang="scss">
.design-view /deep/ .el-dialog__body {
  overflow-y: auto;
}
.view-control-wrap {
  margin-top: 15px;
  display: flex;
  //border-style: solid;
  //border-width: 1px 0 1px 0;
  //border-color: #eee;

  & > div {
    flex: 1;
    padding: 8px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;

    .number {
      font-size: 20px;
      font-weight: bold;
    }
  }

  & > div:last-child {
    border-left: unset;

    .number {
      color: #F90;
    }
  }
}

.goods-header .el-carousel {
  width: 100%;
}

.goods-header .el-input-number {
  width: 150px;
  margin-right: 10px;
}

.goods-header .el-avatar {
  height: 50px;
  width: 50px;
}

.goods-header .shop-info div {
  line-height: 28px;
}

.goods-header .shop-info .title {
  display: inline-block;
  margin-right: 5px;
}

.goods-header .shop-info .value {
  flex: 1;
}

.block-info .block-info-item {
  min-height: 35px;
  display: flex;
}

.block-info .block-info-item div:first-child {
  background-color: #f6f6f6;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid #e5e5e5;
}

.block-info .block-info-item div:nth-child(2) {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.goods-score div {
  position: relative;
}

.goods-score div:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: red;
  width: 1px;
}

.goods-score div span:nth-child(1) {
  color: #F90;
  font-weight: bold;
  margin-bottom: 10px;
}

.evaluate-item + .evaluate-item {
  margin-top: 10px;
}

.evaluate-item /deep/ .el-rate__icon {
  margin-right: 0;
}

.el-divider--horizontal {
  width: 50%;
  transform: translateX(50%)
}

.tips-info {
  padding: 15px;
  line-height: 1.2;
}

.auth_ok_icon {
  height: 12px;
  width: 12px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 3px;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin: 5px 0;
  width: 100%;
}
</style>
